import axios from 'axios'

const baseUrl = 'https://wax.api.atomicassets.io/atomicassets/'

async function getAssets(params) {
  const { data } = await axios.get(baseUrl + 'v1/assets', { params: params })

  if (!data.success) return []

  return data.data
}

async function getAccount(accountName) {
  const { data } = await axios.get(baseUrl + 'v1/accounts/' + accountName)

  if (!data.success) return []

  return data.data
}

async function getTemplate(collection, templateId) {
  const { data } = await axios.get(baseUrl + 'v1/templates/' + collection + '/' + templateId)

  if (!data.success) return []

  return data.data
}

export const $atomic = {
  getAssets,
  getAccount,
  getTemplate
}
