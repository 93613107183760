export default {
  // global theme for the app
  globalTheme: 'dark', // light | dark

  // dark theme colors
  dark: {
    textbase: '#E8E8E8',
    background: '#0c080a',
    surface: '#111b27',
    primary: '#f3c304',
    secondary: '#75787e',
    accent: '#000080',
    error: '#ff2626',
    info: '#f68105',
    success: '#34591f',
    warning: '#FFC107'
  }

  // light theme colors
  // light: {
  //   textbase: '#222222',
  //   background: '#ffffff',
  //   surface: '#f2f5f8',
  //   primary: '#0096c7',
  //   secondary: '#2a4f66',
  //   accent: '#59bbbb',
  //   error: '#ef476f',
  //   info: '#2196F3',
  //   success: '#05c075',
  //   warning: '#ffd166'
  // }
}
