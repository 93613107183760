import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "layout-default" */ '../pages/Home')
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "layout-default" */ '../pages/Inventory'),
    meta: { requiresLogin: true }
  },
  {
    path: '/shipping-box',
    name: 'Shipping Box',
    component: () => import(/* webpackChunkName: "layout-default" */ '../pages/ShippingBox'),
    meta: { requiresLogin: true }
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "layout-error" */ '../layouts/ErrorLayout.vue'),
    children: [{
      path: '',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ '../pages/error/NotFoundPage.vue')
    }]
  }]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLogin) && !store().getters['account/user'].name) {
    // log something here for message popup
    return next('/')
  } else {
    return next()
  }
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
