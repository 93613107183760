import { $bagel } from '@/services/bagle'
import { $atomic } from '@/services/atomic'

export function getProducts({ commit }) {
  $bagel.getProducts().then((response) => {
    Promise.all(response.data.map((product) => {
      return $atomic.getAssets({ owner: 'sandgtraders', template_id: product.templateID }).then((assets) => {
        return assets.map((asset) => {
          asset.price = product.price

          return asset
        })
      })
    })).then((products) => {
      commit('setProducts', products)
    })
  }).catch((error) => {
    console.log(error)
  })
}

export function addCart({ commit, getters }, payload) {
  const { cart } = getters
  const data = payload.product

  data['quantity'] = payload.quantity
  cart.push(data)
  commit('setCart', cart)
}

export function removeCart({ commit, getters }, id) {
  const cart = []

  if (id) {
    for (let index = 0; index < getters.cart.length; index++) {
      const element = getters.cart[index]

      if (element.id !== id) {
        cart.push(element)
      }
    }
  }
  commit('setCart', cart)
}

export function addToBox({ commit, getters }, payload) {
  const { box } = getters

  if (box.filter((bItem) => {
    return bItem.asset_id === payload.asset.asset_id
  }).length > 0) return
  box.push(payload.asset)
  commit('setBox', box)
}

export function removeFromBox({ commit, getters }, payload) {
  const box = getters.box.filter((bItem) => {
    return bItem.asset_id !== payload.asset.asset_id
  })

  commit('setBox', box)
}

export function emptyBox({ commit }) {
  commit('setBox', [])
}
