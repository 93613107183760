export function setProducts(state, val) {
  state.products = val
}

export function setProduct(state, val) {
  state.product = val
}

export function setLoad(state, val) {
  state.uploadingData = val
}

export function setCart(state, val) {
  state.cart = val
}

export function setBox(state, val) {
  state.box = val
}

export function addToBox(state, val) {
  state.box.push(val)
}

export function removeFromBox(state, val) {
  state.box = state.box.filter((item) => item !== val)
}
