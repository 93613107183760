import * as waxjs from '@waxio/waxjs/dist';
import {$atomic} from '@/services/atomic';

function setUserObjectFromWax(commit, wax) {
    commit('setUserWax', wax);
    commit('setUserImage', 'https://wax.api.atomichub.io/v1/preview/avatar/' + wax.userAccount);
    commit('setUserName', wax.userAccount);
    commit('setUserPublicKeys', wax.pubKeys);
}

export function login({commit}) {
    const wax = new waxjs.WaxJS('https://wax.greymass.com', null, null, false);

    wax.login().then(() => {
        setUserObjectFromWax(commit, wax);
    }).catch((error) => {
        console.log(error);
    });
}

export function logout({commit}) {
    commit('removeUser');
}

export function autoLogin({commit, getters}) {
    const user = getters.user;
    if (!user || !user.name) return;

    if (user.publicKeys && user.publicKeys.length) {
        const wax = new waxjs.WaxJS('https://wax.greymass.com', user.name, user.publicKeys, true);
        setUserObjectFromWax(commit, wax);
        return;
    }

    const wax = new waxjs.WaxJS('https://wax.greymass.com', null, null, false);
    wax.isAutoLoginAvailable().then((isLoggedIn) => {
        if (isLoggedIn) {
            setUserObjectFromWax(commit, wax);
        }
    }).catch((error) => {
        console.log(error);
    });
}

export function getUserInventory({commit, getters}) {
    if (!getters.user || !getters.user.name) return;

    $atomic.getAssets({owner: getters.user.name, collection_name: 'nftinsiderio'}).then((assets) => {
        commit('setUserAssets', assets);
    }).catch((error) => {
        console.log(error);
    });
}

export function removeAsset({commit, getters}, asset) {
    const assets = getters.assets.filter((temp) => temp !== asset);

    commit('setUserAssets', assets);
}
