export default function () {
    return {
        userData: {
            name: '',
            publicKeys: [],
            wax: {},
            image: '',
            assets: [],
            collections: [],
        },
    };
}
