import initState from './state'

export function setUserWax(state, val) {
  state.userData.wax = val
}

export function setUserImage(state, val) {
  state.userData.image = val
}

export function setUserName(state, val) {
  state.userData.name = val
}

export function setUserAssets(state, val) {
  state.userData.assets = val
}

export function setUserCollections(state, val) {
  state.userData.collections = val
}

export function removeUser(state) {
  state.userData = initState.userData
}

export function setUserPublicKeys(state, val) {
    state.userData.publicKeys = val;
}
