<template>
  <v-app>
    <Header/>
    <router-view/>
    <Footer/>
  </v-app>
</template>

<script>
import config from './configs'
import Header from '@/components/local/Header'
import Footer from '@/components/local/Footer'
import { mapActions } from 'vuex'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  components: {
    Header,
    Footer
  },
  mounted() {
    this.autoLogin()
  },
  methods: {
    ...mapActions('account', ['autoLogin'])
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>
