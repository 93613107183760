import Vue from 'vue';
import Vuex from 'vuex';
import account from './account';
import product from './product';
import createPersistedState from 'vuex-persistedstate';
//import * as Cookies from 'js-cookie'

Vue.use(Vuex);
export default function () {
    return new Vuex.Store({
        modules: {
            account,
            product,
        },
        strict: process.env.DEV,
        plugins: [
            createPersistedState({
                paths: ['product', 'account.userData.name', 'account.userData.publicKeys'],
            }),
        ],
    });
}
