export function products(state) {
  return state.products
}

export function product(state) {
  return state.product
}

export function cart(state) {
  return state.cart
}

export function box(state) {
  return state.box
}
