import Bagel from '@bageldb/bagel-db'

const db = new Bagel('eyJhbGciOiJSUzI1NiIsImtpZCI6ImFwaS1rZXkiLCJ0eXAiOiJKV1QifQ.eyJvcmdhbml6YXRpb25JRCI6ImMyZzhpbXEyM2FrZzAwZGJvaHFnIiwicHJvamVjdElEIjoiYzJnOGlwaTIzYWtnMDBkYm9odTAiLCJhdWQiOiJodHRwczovL2FwcC5iYWdlbGRiLmNvbSIsImp0aSI6IjEyOThmY2NmLTUyN2UtNDI5ZS04ZTVhLTk4NTFlNGY2M2I0NSIsImlzcyI6Imh0dHBzOi8vYXV0aGVudGljYXRpb24uYmFnZWxkYi5jbyIsInN1YiI6ImMyZzhpcGkyM2FrZzAwZGJvaHUwIn0.PfY0cKj4E6XvV7Ah_JjWTVIqZHxGgozhVlRP-5qYxHjYp2gLlE89kxdemM9OkQFiDjHn_LLzHRvb89HPM76l0xMNKSg5f2djC77fJZhzC5_EniEmaIpSZtD3YHqXG940fKoDcgOBgkTUTpqHH_DkdVBuRi7EJ-UgPeHv_TXdRc5j-A1KQ3WiqkcBVjNXNnmXucZy77gB9SdfHR_58Njd0vO_pUC169BrNBaHetbQ9wNxyy_d-bPbStSnJUQKacZr_wTGVZteWek_JUkP9gj3_QUQGzajSJASQhRuZAo_44C3GBEOYq0gREM2nYZrAR_Bh2Yqts0-V3rTGWj7gjBKiO-jK-RzCVLSVG3QigLbXPENfAwa46fkkB1msIQCeXjmZkMwjHMFTIOzLIgSItNu4s9dtLpM7DeGJQjYUCfylh7NTeGNdsEJyQfLxUlMXZXf3I_PIu5pBYoawd4uUaA6ZJ5HT73RR4L-pLKs2a2Oka2eO44wxR9dTQMoxryxEHmRao9ANgwItck8reNUGM8I_9IOV-wSAQq3Osjr7hHclPTHtezJZjHL6TidnI358f403C2kg_znMrcgLCDzHeSIU2MNbJpy1_pTYAnMp0caL_h_P9fkzhU5YNL_iTFOQdBntV4ipA48m2uDuUYhjsEZ8razAn-Xo7LPKlFVVqSSPT8')

async function getProducts() {
  return db.collection('products').get()
}

async function getWallets() {
  return db.collection('wallets').get()
}

export const $bagel = {
  getProducts,
  getWallets
}
