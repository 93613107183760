<template>
  <div>
    <v-app-bar flat height="80" color="transparent">
      <v-container class="py-0 px-0 px-sm-2 fill-height">
        <router-link to="/" class="d-flex align-center text-decoration-none mr-2 toolbar3-min-width">
          <span class="font-weight-black text-uppercase text-h2">
            S&G
          </span>
        </router-link>

        <v-spacer></v-spacer>

        <div>
          <div v-if="user.name" class="login-account">
            <v-menu
              bottom
              min-width="200px"
              rounded
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  x-large
                  color="primary"
                  v-on="on"
                >
                  <v-badge
                    overlap
                    :content="box.length"
                    :value="box.length"
                    class="background--text"
                  >
                    <v-avatar size="60">
                      <v-img :src="user.image" :alt="user.name"></v-img>
                    </v-avatar>
                  </v-badge>
                </v-btn>

              </template>
              <v-card color="surface">
                <v-list-item-content class="justify-center">
                  <div class="mx-auto text-center">
                    <h3>{{ user.name }}</h3>
                    <v-divider class="my-2"></v-divider>
                    <v-btn
                      depressed
                      rounded
                      text
                      :to="{name: 'Inventory'}"
                    >
                      Inventory
                    </v-btn>
                    <div v-if="box.length">
                      <v-divider class="my-2"></v-divider>
                      <v-btn
                        depressed
                        rounded
                        text
                        :to="{name: 'Shipping Box'}"
                      >
                        <v-badge
                          :content="box.length"
                          :value="box.length"
                        >
                          Shipping Box
                        </v-badge>
                      </v-btn>
                    </div>
                    <v-divider class="my-2"></v-divider>
                    <v-btn
                      depressed
                      rounded
                      text
                      @click="logoutAndRedirect()"
                    >
                      Logout
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </div>
          <v-btn
            v-else
            color="primary"
            large
            outlined
            @click="login()"
          >Login
          </v-btn>

        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      drawer: null,
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('product', ['cart', 'box'])
  },
  mounted() {
    //this.autoLogin()
  },
  methods: {
    ...mapActions('account', ['login', 'autoLogin', 'logout']),

    logoutAndRedirect() {
      this.logout()
      this.$router.go('/')
    }
  }
}
</script>

<style scoped>

</style>
